.displayarea-wrapper {
	padding: 1rem;
	max-width: 100%;
	max-height: 60vh;
	min-height: 60vh;
	overflow: auto;
	text-align: justify;
	border: solid rgb(163, 162, 162) 0.2rem;
	background-color: lightgrey;
	margin: 0.4rem;
	border-radius: 0.5rem;
}
